import React, { useState, useRef, useEffect } from "react";
import { MessageCircle, Send, X, Bot, User } from "lucide-react";

const Chatbot: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<{ sender: "bot" | "user"; text: string }[]>([]);
  const [userMessage, setUserMessage] = useState("");
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const toggleChat = () => {
    setIsOpen(!isOpen);

    if (!isOpen && messages.length === 0) {
      setMessages([
        {
          sender: "bot",
          text: "Ciao! Sono l'assistente virtuale di Bostmoto, come posso aiutarti?",
        },
      ]);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const questionsCatalog: { [key: string]: { [key: string]: string } } = {
    orari: {
      it: "Siamo aperti dal lunedì al venerdì, dalle 8:00 alle 19:00.",
      en: "We are open from Monday to Friday, from 8:00 AM to 7:00 PM.",
    },
    chiusura: {
      it: "Chiudiamo ogni giorno lavorativo alle 19:00.",
      en: "We close at 7:00 PM on working days.",
    },
    pausa: {
      it: "La nostra pausa pranzo è dalle 14:00 alle 15:00.",
      en: "Our lunch break is from 2:00 PM to 3:00 PM.",
    },
    domenica: {
      it: "Non lavoriamo la domenica.",
      en: "We do not work on Sundays.",
    },
    contatti: {
      it: "Puoi contattarci al numero 081 1856 7249 o via email a info@bostmoto.it.",
      en: "You can contact us at 081 1856 7249 or via email at info@bostmoto.it.",
    },
    appuntamenti: {
      it: "Puoi prenotare un appuntamento direttamente dal nostro sito o contattandoci.",
      en: "You can book an appointment directly from our website or by contacting us.",
    },
    riparazioni: {
      it: "Effettuiamo riparazioni su moto di ogni marca e modello.",
      en: "We perform repairs on motorcycles of all makes and models.",
    },
    revisioni: {
      it: "Offriamo il servizio di revisione ufficiale per moto e scooter Honda.",
      en: "We provide official inspection services for Honda motorcycles and other brands.",
    },
    manutenzione: {
      it: "La manutenzione regolare garantisce la massima efficienza della tua moto.",
      en: "Regular maintenance ensures maximum efficiency for your motorcycle.",
    },
    servizi: {
      it: "Offriamo servizi di manutenzione, riparazione e diagnostica per moto e scooter honda.",
      en: "We offer maintenance, repair, and diagnostics for Honda motorcycles.",
    },
    pagamento: {
      it: "Accettiamo contanti, carte di credito e bonifici bancari.",
      en: "We accept cash, credit cards, and bank transfers.",
    },
    consegna: {
      it: "Ci impegniamo a consegnarti la tua moto nel minor tempo possibile!",
      en: "We strive to deliver your motorcycle as quickly as possible!",
    },
    sconosciuto: {
      it: "Mi dispiace, non posso rispondere a questa domanda.",
      en: "Sorry, I cannot answer that question.",
    },
  };

  const getResponseKey = (message: string): string => {
    const lowerCaseMessage = message.toLowerCase();
    if (lowerCaseMessage.includes("aperti") || lowerCaseMessage.includes("open")) return "orari";
    if (lowerCaseMessage.includes("chiudete") || lowerCaseMessage.includes("close")) return "chiusura";
    if (lowerCaseMessage.includes("pausa") || lowerCaseMessage.includes("lunch")) return "pausa";
    if (lowerCaseMessage.includes("domenica") || lowerCaseMessage.includes("sunday")) return "domenica";
    if (lowerCaseMessage.includes("contatti") || lowerCaseMessage.includes("contact")) return "contatti";
    if (lowerCaseMessage.includes("appuntamento") || lowerCaseMessage.includes("appointment")) return "appuntamenti";
    if (lowerCaseMessage.includes("riparazioni") || lowerCaseMessage.includes("repairs")) return "riparazioni";
    if (lowerCaseMessage.includes("revisioni") || lowerCaseMessage.includes("inspections")) return "revisioni";
    if (lowerCaseMessage.includes("manutenzione") || lowerCaseMessage.includes("maintenance")) return "manutenzione";
    if (lowerCaseMessage.includes("servizi") || lowerCaseMessage.includes("services")) return "servizi";
    if (lowerCaseMessage.includes("garanzia") || lowerCaseMessage.includes("warranty")) return "garanzia";
    if (lowerCaseMessage.includes("pagamento") || lowerCaseMessage.includes("payment")) return "pagamento";
    if (lowerCaseMessage.includes("consegna") || lowerCaseMessage.includes("delivery")) return "consegna";
    return "sconosciuto";
  };

  const sendMessage = () => {
    if (userMessage.trim() === "") return;

    const newMessages = [...messages, { sender: "user", text: userMessage }];
    setMessages(newMessages);
    setUserMessage("");

    const responseKey = getResponseKey(userMessage);
    const userLang = navigator.language.startsWith("it") ? "it" : "en";
    const botResponse = questionsCatalog[responseKey][userLang];

    setTimeout(() => {
      setMessages((prev) => [...prev, { sender: "bot", text: botResponse }]);
    }, 500);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  return (
    <div className="fixed bottom-6 right-6 z-50">
      <div
        className={`
          transform transition-all duration-300 ease-in-out origin-bottom-right
          ${isOpen ? 'scale-100 opacity-100' : 'scale-0 opacity-0'}
          flex flex-col w-full max-w-md h-[600px] bg-gradient-to-br from-gray-900 to-gray-800
          border border-red-500/20 rounded-2xl shadow-2xl overflow-hidden
        `}
      >
        {/* Header */}
        <div className="flex items-center justify-between px-6 py-4 bg-gradient-to-r from-red-600 to-red-500 shadow-lg">
          <div className="flex items-center space-x-2">
            <Bot className="w-6 h-6 text-white" />
            <h3 className="text-lg font-semibold text-white">Bostmoto Assistant</h3>
          </div>
          <button
            onClick={toggleChat}
            className="p-1 rounded-full hover:bg-red-400/20 transition-colors"
          >
            <X className="w-6 h-6 text-white" />
          </button>
        </div>

        {/* Messages */}
        <div className="flex-1 overflow-y-auto px-6 py-4 space-y-4 scrollbar-thin scrollbar-thumb-red-500 scrollbar-track-transparent">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`flex items-start space-x-2 ${
                msg.sender === "user" ? "flex-row-reverse space-x-reverse" : ""
              }`}
            >
              <div className={`
                flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center
                ${msg.sender === "bot" ? "bg-red-500" : "bg-gray-700"}
              `}>
                {msg.sender === "bot" ? (
                  <Bot className="w-5 h-5 text-white" />
                ) : (
                  <User className="w-5 h-5 text-white" />
                )}
              </div>
              <div className={`
                max-w-[80%] px-4 py-2 rounded-2xl
                ${msg.sender === "bot" 
                  ? "bg-gray-700 text-white" 
                  : "bg-red-500 text-white"}
                transform transition-all duration-300 ease-out
                animate-[slideIn_0.3s_ease-out]
              `}>
                <p className="text-sm">{msg.text}</p>
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        {/* Input */}
        <div className="p-4 bg-gray-800 border-t border-gray-700">
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyDown={handleKeyPress}
              placeholder="Scrivi un messaggio..."
              className="flex-1 px-4 py-2 bg-gray-700 border border-gray-600 rounded-full
                text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                transition-colors"
            />
            <button
              onClick={sendMessage}
              className="p-2 bg-red-500 rounded-full hover:bg-red-600 
                transform active:scale-95 transition-all duration-200"
            >
              <Send className="w-5 h-5 text-white" />
            </button>
          </div>
        </div>
      </div>

      {/* Toggle Button */}
      <button
        onClick={toggleChat}
        className={`
          absolute bottom-0 right-0 p-4 bg-red-500 rounded-full shadow-lg
          hover:bg-red-600 transform hover:scale-105 active:scale-95
          transition-all duration-200 ease-in-out
        `}
      >
        {isOpen ? (
          <X className="w-6 h-6 text-white" />
        ) : (
          <MessageCircle className="w-6 h-6 text-white" />
        )}
      </button>
    </div>
  );
};

export default Chatbot;