import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Mission from './components/Mission';
import Services from './components/Services';
import Systems from './components/Systems';
import Partners from './components/Partners';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import WhereWeAre from './components/WhereWeAre';
import Guide from './components/Guide';
{/*import Contact from './components/Contact';*/}
import Footer from './components/Footer';
import Chatbot from './components/ChatBot';
import MaintenanceMode from './components/MaintenanceMode';

function App() {
  const isAuthorized = localStorage.getItem('adminAccess') === 'your-unique-key';
  const isMaintenance = false; // Imposta questa variabile in base alla necessità
  
  return (
    <div className="min-h-screen bg-black text-white">
      {isMaintenance && !isAuthorized ? (
        <MaintenanceMode /> // Mostra la modalità manutenzione solo per utenti non autorizzati
      ) : (
        <>
          <header>
            <Navbar />
          </header>
          <main>
            <Hero />
            <Chatbot /> {/* Aggiungi il chatbot qui */}
            <About />
            <Mission />
            <Services />
            <Systems />
            <Partners />
            <Gallery />
            <Testimonials />
            <Guide />
            <WhereWeAre />
          </main>
          <footer>
            <Footer />
          </footer>
        </>
      )}
    </div>
  );
  
}

export default App;
